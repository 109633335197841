<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6" offset-md="3" lg="4" offset-lg="4">
          <b-row>
            <b-col>
              <div class="text-center">
                <feather-icon icon="CheckCircleIcon" size="150" color="#79B42B" v-if="examResult.isPass === 'Y'"/>
                <feather-icon icon="XCircleIcon" size="150" color="#E01C1A" v-else/>
                <h1 class="mt-2 mb-3" v-if="examResult.isPass === 'Y'">{{ $t('training.resultPass') }}</h1>
                <h1 class="mt-2 mb-3" v-else>{{ $t('training.resultNotPass') }}</h1>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col>{{ $t('training.resultName') }}</b-col>
            <b-col><strong>{{ examResult.examiner }}</strong></b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col>{{ $t('training.resultTrainingDate') }}</b-col>
            <b-col><strong>{{ examResult.examDate }}</strong></b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col>{{ $t('training.resultExpireDate') }}</b-col>
            <b-col><strong>{{ examResult.examExpireDate }}</strong></b-col>
          </b-row>

          <b-row class="mb-1 text-center">
            <b-col>
              <h3>{{ $t('training.score') }}: {{ examResult.examScore }}</h3>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-card>

    <b-row>
      <b-col class="text-center">
        <b-overlay>
          <b-button variant="success" class="my-1" @click="goBack">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t('general.btnBack') }}
          </b-button>
        </b-overlay>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BOverlay, BRow, BSpinner} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
  },
  created() {
    this.examResult = this.$store.state.training.examResult
  },
  destroyed() {
    this.$store.commit('training/setExamResult', {})
  },
  data: () => ({
    examResult: {}
  }),
  methods: {
    goBack() {
      this.$router.replace({name: 'pageTraining'})
    }
  }
}
</script>
